/* eslint-disable import/no-named-as-default */
import { combineReducers } from 'redux';
import userSlice from './reducers/userSlice';
import appSlice from './reducers/appSlice';

const appReducer = combineReducers({
	userSlice,
	appSlice,
});

export default appReducer;
