/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
// import { Routes, Route, Outlet, Link } from "react-router-dom";
import "./App.css";
// import pattern from "./assets/images/pattern.png";
// import pnbMetlifeLogo from "./assets/images/pnbMetlifeLogo.png";
// import getincomeicon from "./assets/images/get-income-icon.png";
// import certificate from "./assets/images/certificate.png";
// import cashicon from "./assets/images/cash-icon.png";

// import bigmoneyicon from "./assets/images/bigmoney-icon.png";
// import securepayment from "./assets/images/secure-payment.png";
// import timer from "./assets/images/timer-icon.png";
// import documentdownload from "./assets/images/document-download.png";
// import watchvideo from "./assets/images/watch-video.png";
import MainRoutes from "./routes/mainRoutes";

const App = () => {
  return (
    <div>
      <MainRoutes />
    </div>
  );
};

export default App;

// function Layout() {
//   return (
//     <div className="container g-0 container-bg">
//       {/* A "layout route" is a good place to put markup you want to
//           share across all the pages on your site, like navigation. */}
//       {/* <nav>
//         <ul>
//           <li>
//             <Link to="/">Home</Link>
//           </li>
//           <li>
//             <Link to="/about">About</Link>
//           </li>
//           <li>
//             <Link to="/dashboard">Dashboard</Link>
//           </li>
//           <li>
//             <Link to="/nothing-here">Nothing Here</Link>
//           </li>
//         </ul>
//       </nav> */}

// <div className="logo-bg-container">
//           <div className="logo-bg"></div>
//         </div>
//         <div className="circle-pattern-container">
//           <div className="circle-pattern"></div>
//         </div>
//         <div className="dot-pattern">
//           <img src={pattern}></img>
//         </div>
//         <header className="pb-3 py-4 mb-5" style={{ position: "relative" }}>
//           <a
//             href="/"
//             className="d-flex align-items-center text-dark text-decoration-none"
//           >
//             <img src={pnbMetlifeLogo} className="logo" />
//           </a>
//         </header>

//       {/* An <Outlet> renders whatever child route is currently active,
//           so you can think about this <Outlet> as a placeholder for
//           the child routes we defined above. */}
//       <Outlet />
//     </div>
//   );
// }
// function OtpValidate() {
//   return (
//     <div>

//         <div className="row g-0">
//           <div className="col-md-12">
//             <h1 className="title-heading">PNB MetLife Plan : OTP Validation </h1>
//           </div>
//         </div>

//         <div className="row mt-12">
//           <div className="col-12 col-md-12">
//             <div className="card shadow-soft border-light">
//               <div className="card-body">
//                 <div className="mb-3">
//                       <label htmlFor="exampleFormControlInput1" className="form-label">Mobile  Number</label>
//                       <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com"></input>
//                   </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="row g-0">
//           <div className="col-md-12">
//             <h1 className="title-heading">To Know More</h1>
//           </div>
//         </div>
//         <footer>
//           <div className="d-flex justify-content-center mb-6">
//             <Link className="btn  btn-outline-primary btn-lg mx-1" to="/">
//               Click To Review
//             </Link>
//             {/* <a className="btn btn-primary btn-lg  mx-1">Next</a> */}
//             <Link className="btn btn-primary btn-lg  mx-1" to="/home">Next</Link>
//           </div>
//         </footer>
//       </div>
//   );
// }

// function Home() {
//   return (
//     <div>

//         <div className="row g-0">
//           <div className="col-md-12">
//             <h1 className="title-heading">PNB MetLife Century Plan</h1>
//           </div>
//         </div>
//         <div className="row mt-6">
//           <div className="col-12 col-md-6">
//             <div className="card shadow-soft border-light">
//               <div className="card-body">
//                 <div className="d-flex flex-lg-row">
//                   <div className="mb-lg-0">
//                     <div className="icon ">
//                       <img src={getincomeicon} />
//                     </div>
//                   </div>
//                   <div className="card-text">
//                     <h4 className="mb-3">
//                       Get income and protection for whole life.
//                     </h4>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="col-12 col-md-6">
//             <div className="card shadow-soft border-light">
//               <div className="card-body">
//                 <div className="d-flex flex-lg-row">
//                   <div className="mb-lg-0">
//                     <div className="icon ">
//                       <img src={certificate} />
//                     </div>
//                   </div>
//                   <div className="card-text">
//                     <h4 className="mb-3">
//                       Leave a legacy for your loved once.
//                     </h4>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="row mt-6">
//           <div className="col-12 col-md-6">
//             <div className="card shadow-soft border-light">
//               <div className="card-body">
//                 <div className="d-flex flex-lg-row">
//                   <div className="mb-lg-0">
//                     <div className="icon ">
//                       <img src={cashicon} />
//                     </div>
//                   </div>
//                   <div className="card-text">
//                     <h4 className="mb-3">Get Guaranteed Cash bonus</h4>
//                     <p>@10% under Suuper Income option</p>
//                     <p>@30% under Future Income option</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="col-12 col-md-6">
//             <div className="card shadow-soft border-light">
//               <div className="card-body">
//                 <div className="d-flex flex-lg-row">
//                   <div className="mb-lg-0">
//                     <div className="icon ">
//                       <img src={bigmoneyicon} />
//                     </div>
//                   </div>
//                   <div className="card-text">
//                     <h4 className="mb-3">
//                       Ensure survival benefits continue to be paid even after
//                       death of the Life Assured and no future premiums are
//                       payable after death.
//                     </h4>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="row mt-6">
//           <div className="col-12 col-md-6">
//             <div className="card shadow-soft border-light">
//               <div className="card-body">
//                 <div className="d-flex flex-lg-row">
//                   <div className="mb-lg-0">
//                     <div className="icon ">
//                       <img src={securepayment} />
//                     </div>
//                   </div>
//                   <div className="card-text">
//                     <h4 className="mb-3">
//                       Safeguard your Financial needs even after retirement.
//                     </h4>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="col-12 col-md-6">
//             <div className="card shadow-soft border-light">
//               <div className="card-body">
//                 <div className="d-flex flex-lg-row">
//                   <div className="mb-lg-0">
//                     <div className="icon ">
//                       <img src={timer} />
//                     </div>
//                   </div>
//                   <div className="card-text">
//                     <h4 className="mb-3">
//                       Enjoy the flexibility to accumulate the survival benefits
//                       payouts and withdraw as and when required.
//                     </h4>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="row g-0">
//           <div className="col-md-12">
//             <h1 className="title-heading">To Know More</h1>
//           </div>
//         </div>
//         <div className="row mt-6  g-2">
//           <div className="col-sm-12 col-md-12  col-lg-6">
//             <div className="mb-6 d-flex justify-content-center align-items-center">
//               <a href="#" className="gradient-btn">
//                 <img src={documentdownload} />
//                 Policy Document Download
//               </a>
//             </div>
//           </div>
//           <div className="col-sm-12  col-md-12  col-lg-6">
//             <div className="mb-6 d-flex justify-content-center align-items-center">
//               <a href="#" className="gradient-btn">
//                 <img src={watchvideo} />
//                 Product Video
//               </a>
//             </div>
//           </div>
//         </div>
//         <footer>
//           <div className="d-flex justify-content-center mb-6">
//             <Link className="btn  btn-outline-primary btn-lg mx-1" to="/home">
//               Click To Review
//             </Link>
//             {/* <a className="btn btn-primary btn-lg  mx-1">Next</a> */}
//             <Link className="btn btn-primary btn-lg  mx-1" to="/about">Next</Link>
//           </div>
//         </footer>
//       </div>
//   );
// }

// function About() {
//   return (
//     <div>

//     <div className="row g-0">
//       <div className="col-md-12">
//         <h1 className="title-heading">PNB MetLife Century Plan</h1>
//       </div>
//     </div>
//     <div className="row mt-6">
//       <div className="col-12 col-md-6">
//         <div className="card shadow-soft border-light">
//           <div className="card-body">
//             <div className="d-flex flex-lg-row">
//               <div className="mb-lg-0">
//                 <div className="icon ">
//                   <img src={getincomeicon} />
//                 </div>
//               </div>
//               <div className="card-text">
//                 <h4 className="mb-3">
//                   Get income and protection for whole life.
//                 </h4>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="col-12 col-md-6">
//         <div className="card shadow-soft border-light">
//           <div className="card-body">
//             <div className="d-flex flex-lg-row">
//               <div className="mb-lg-0">
//                 <div className="icon ">
//                   <img src={certificate} />
//                 </div>
//               </div>
//               <div className="card-text">
//                 <h4 className="mb-3">
//                   Leave a legacy for your loved once.
//                 </h4>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>

//     <div className="row g-0">
//       <div className="col-md-12">
//         <h1 className="title-heading">To Know More</h1>
//       </div>
//     </div>
//     <div className="row mt-6  g-2">
//       <div className="col-sm-12 col-md-12  col-lg-6">
//         <div className="mb-6 d-flex justify-content-center align-items-center">
//           <a href="#" className="gradient-btn">
//             <img src={documentdownload} />
//             Policy Document Download
//           </a>
//         </div>
//       </div>
//       <div className="col-sm-12  col-md-12  col-lg-6">
//         <div className="mb-6 d-flex justify-content-center align-items-center">
//           <a href="#" className="gradient-btn">
//             <img src={watchvideo} />
//             Product Video
//           </a>
//         </div>
//       </div>
//     </div>
//     <footer>
//       <div className="d-flex justify-content-center mb-6">
//       <Link className="btn  btn-outline-primary btn-lg mx-1" to="/about">
//               Click To Review
//       </Link>
//         <Link className="btn btn-primary btn-lg  mx-1" to="/dashboard" >Next</Link>
//       </div>
//     </footer>
//   </div>
//   );
// }

// function Dashboard() {
//   return (
//     <div>
//        <Link className="btn btn-primary btn-lg  mx-1" to="/" >Back to Home</Link>
//     </div>
//   );
// }

// function NoMatch() {
//   return (
//     <div>
//       <h2>Nothing to see here!</h2>
//       <p>
//         <Link to="/">Go to the home page</Link>
//       </p>
//     </div>
//   );
// }
