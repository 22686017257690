/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { useNavigate } from "react-router-dom";

const AppLanguage = () => {
    let navigate = useNavigate();
    const gotoOtp=()=>{
        navigate("/otp")
    }
  return (
    <>
      <div className="opt-box row m-0 pt-3 pb-3">
        <div className="col-sm-6 mx-auto">
          <h5 className="text-center mb-2">
            <strong className="mb-1">PLease choose your Language</strong>
          </h5>
        </div>
      </div>
      <div className="row  m-0">
        <div className="col-sm-3 mx-auto">
          <div className="language-selector">
            <select className="form-select" aria-label="Default select example" onChange={gotoOtp}>
              <option selected>select Language</option>
              <option value="1">English</option>
              <option value="2">Hindi</option>
              <option value="3">Punjabi</option>
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppLanguage;
