import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { DEVELOPMENT, GET_ENVIRONMENT } from '../utils/constants';
import rootReducers from './rootReducers';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['userSlice'], // which reducer want to store
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

const configureStore = () => {
	const MiddleWares = [thunk];
	if (GET_ENVIRONMENT === DEVELOPMENT) MiddleWares.push(logger); // logger only in development
	const allMiddleWares = applyMiddleware(...MiddleWares);

	const reduxStore = createStore(persistedReducer, allMiddleWares);

	const persistor = persistStore(reduxStore);

	return { store: reduxStore, persistor };
};

export default configureStore;
