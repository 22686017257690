import React from 'react'
import { Routes, Route} from 'react-router-dom'
import Layoutpnbmetlife from '../layouts/MainLayout'
import Home from '../pages/Home'
import AppLanguage from '../pages/Language'
// import NoMatch from '../pages/NoMatch'
import OtpValidate from '../pages/OTP'
import PolicyDetails from '../pages/PolicyDetails'
import WrongPloicy from '../pages/WrongPloicy'



const  MainRoutes =()=> {
  // const [uniqueId, setUniqueId] = useState('12345')

  return (
   <>
      <Routes>
        <Route path="/" element={<Layoutpnbmetlife />}>
          <Route path="/pnbmetlife/p">
          {/* <Route index element={<OtpValidate />} /> */}

          <Route path=":uniqueId" element={<OtpValidate />} />
            <Route path="home/:uniqueId" element={<Home />} />
            <Route path="policy/:uniqueId" element={<PolicyDetails />} />
            <Route path="language/:uniqueId" element={<AppLanguage />} />
            <Route path="wrongPloicy" element={<WrongPloicy />} />
          </Route>



          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          {/* <Route path="*" element={<NoMatch />} /> */}
        </Route>
      </Routes>
   </>
  )
}


// const MainComponent = () => {
//     const navigate = useNavigate();
//     useEffect(()=>{
//       navigate(`/pnbmetlife/MjYvYXBfbWV0bA`)

//     });

//     return (
//         <div>
//         </div>
//     );
// }


export default MainRoutes
