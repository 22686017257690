// import React from "react";
// import ReactDOM from "react-dom/client";
// import { BrowserRouter } from "react-router-dom";
// import { HelmetProvider } from "react-helmet-async";
// import "bootstrap/dist/css/bootstrap.css";
// // Put any other imports below so that CSS from your
// // components takes precedence over default styles.
// import "./index.css";
// import "./custom.scss";
// import App from "./App";
// import {
//   createBrowserRouter,
//   RouterProvider,
// } from "react-router-dom";
// import reportWebVitals from "./reportWebVitals";

// const router = createBrowserRouter([
//   {
//     path: "/hello",
//     element: <App />,
//   },
// ]);

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );
// root.render(
//   <HelmetProvider>
//     <React.StrictMode>
//     <RouterProvider router={router} />
//       {/* <BrowserRouter basename="pnbmetlife/">
//         <App />
//       </BrowserRouter> */}
//     </React.StrictMode>
//   </HelmetProvider>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from "react";
import ReactDOM from "react-dom/client";
// import { BrowserRouter } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "bootstrap/dist/css/bootstrap.css";
import log from 'loglevel';
// Put any other imports below so that CSS from your
// components takes precedence over default styles.
import "./index.css";
import "./custom.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GET_ENVIRONMENT, PRODUCTION } from './utils/constants';
import configureStore from './store/index';

const storeObject = configureStore();
const { store, persistor } = storeObject;
if (GET_ENVIRONMENT !== PRODUCTION) log.enableAll();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <HelmetProvider>
    <React.StrictMode>
      <BrowserRouter>
      <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />

        </PersistGate>
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

