/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import moment from "moment";
import { useSelector } from 'react-redux'
import getincomeicon from "../../assets/images/get-income-icon.png";
import certificate from "../../assets/images/certificate.png";
import cashicon from "../../assets/images/cash-icon.png";
import axios from 'axios';
import { getDecodedString } from "../../utils/Encoding";





const PolicyDetails = () => {
  const { policyDetail, uniqueID } = useSelector((state) => state.userSlice);
  console.log('policyDetail---->>', policyDetail);

  const onHandleRevive = () => {
    const url = `https://staging.pelocal.com/n/b2b/api/v1/ap/revive_policy/${uniqueID}/`;
    axios.post(url).then((response) => {
      // console.log('response--->>',response);
      if (response.data !== undefined) {
        const dataSuccess = getDecodedString(response?.data);
        // console.log('decoded response---->>', dataSuccess);
        window.open(dataSuccess.data);
      }
      // if(response.data.success){
      //   window.open(response.data.pmt_url);
      // }
    });
  }

  const inputDate = policyDetail?.premiumdueDate;
  const formattedDate = moment(inputDate, "DD/MM/YYYY").format("D MMMM YYYY");

  return (
    <>
      <div className="row mt-6 m-0">
        <div className="col-12">
          <div className="mb-6">
            <strong className="m-0 mx-1">Dear,</strong>
            <p className="gradient-btn w-100  d-flex justify-content-between pt-1 pb-1 roundedd">
              <span>{policyDetail.customerName}</span>
            </p>
            <h5 className="pnb-yellow"><strong>Your Policy is <br />
              <samp className="text-uppercase">PNB MetLife Century Plan <br /> </samp>which is </strong></h5>
            <ul className="list-unstyled p-0 plan-features">
              <li className="text-uppercase d-inline-block">{policyDetail.policyStatus}</li>
              {/* <li className="text-uppercase d-inline-block">NON-LINKED</li>
                <li className="text-uppercase d-inline-block">PARTICIPATING</li>
                <li className="text-uppercase d-inline-block">SAVING</li>
                <li className="text-uppercase d-inline-block pl-2">LIFE INSURANCE PLAN</li> */}
            </ul>
            <p>Thats provides of an opportunity to fulfil your dreams and secure your life</p>
            <h6><strong>We would Like to inform you</strong></h6>
          </div>
        </div>
      </div>
      <div className="seablue-grad p-4">
        <div className="row mt-6 m-0 justify-content-between pl-details">
          <label><strong>Policy Number</strong></label>
          <div className=" d-flex gradient-bg align-items-center pl-item mb-3">
            <div className="mb-lg-0">
              <div className="icon ">
                <img src={getincomeicon} />
              </div>
            </div>
            <div className="card-text">
              <p>{policyDetail.policyNumber}</p>
            </div>
          </div>
          <label><strong>Plan Number</strong></label>
          <div className="d-flex gradient-bg align-items-center pl-item mb-3">
            <div className=" mb-lg-0">
              <div className="icon ">
                <img src={certificate} />
              </div>
            </div>
            <div className="card-text">
              <p>{policyDetail.premiumFreq}</p>
            </div>
          </div>
          <label><strong>Lapsed Since</strong></label>
          <div className="d-flex gradient-bg align-items-center pl-item mb-2">
            <div className=" mb-lg-0">
              <div className="icon ">
                <img src={cashicon} />
              </div>
            </div>
            <div className="card-text">
              {/* <p>24 December 2022</p> */}
              <p>{formattedDate}</p>
            </div>
          </div>
        </div>


        <div className="d-flex justify-content-center mb-6 mt-3">
          <button className="btn next btn-primary btn-lg  mx-1" onClick={onHandleRevive}>Click To Revive</button>
        </div>


      </div>
    </>
  )
}

export default PolicyDetails