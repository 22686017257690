import * as fernet from 'fernet';
import { log } from 'loglevel';
// import { RandomArray } from './constants';

const secretToken = new fernet.Secret(process.env.REACT_APP_ENCODING_KEY);

// export function getEncodedString(data) {
// 	if (data === null) {
// 		log.debug('Error Encoding Data is null', data);
// 		return 0;
// 	}
// 	const EncodingToken = new fernet.Token({
// 		secret: SecretToken,
// 		time: Date.parse(1),
// 		iv: RandomArray,
// 	});
// 	if (typeof data === 'string') return EncodingToken.encode(data);
// 	return EncodingToken.encode(JSON.stringify(data));
// }

export function getDecodedString(EncodedString) {
	if (EncodedString === null) {
		log.debug('Error Encoded  String is null', EncodedString);
		return 0;
	}
	const DecodingToken = new fernet.Token({
		secret: secretToken,
		token: EncodedString,
		ttl: 0,
	});

	const data = DecodingToken.decode();
	return JSON.parse(data);
}
