/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Outlet } from "react-router-dom";
import pattern from "../../assets/images/pattern.png";
import Header from "../../components/Header";

const Layoutpnbmetlife = () => {
  return (
    <>
      <div className="container  container-bg">
      <div className="logo-bg-container">
          <div className="logo-bg"></div>
        </div>
        <div className="circle-pattern-container">
          <div className="circle-pattern"></div>
        </div>
        <div className="dot-pattern">
          <img src={pattern} className="img-fluid"></img>
        </div>
        <Header/>
        <main>
        <Outlet />
        </main>
        {/* <footer>
        </footer> */}
      </div>
    </>
  );
};

export default Layoutpnbmetlife;
