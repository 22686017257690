// import uploadIcon from 'assets/images/sidebar_icon_1.svg';
// import MerchantIcon from 'assets/images/sidebar_icon_2.svg';
// import paymentIcon from 'assets/images/sidebar_icon_3.svg';
// import customerIcon from 'assets/images/sidebar_icon_4.svg';
// import reportIcon from 'assets/images/sidebar_icon_5.svg';
// import settingIcon from 'assets/images/sidebar_icon_6.svg';
// import sideBarSubUserIcon from 'assets/images/side_bar_sub_user.svg';
// import campaignIcon from 'assets/images/sidebar_icon_7.svg';
// import dashBoardIcon from 'assets/images/sidebar_icon_8.svg';
// import reminderIcon from 'assets/images/reminderIcon.svg';

// import {
// 	BULK_UPLOAD_URL,
// 	B2B_ADD_CAMPAIGN_URL,
// 	B2B_UPDATE_CAMPAIGN_URL,
// 	B2B_UCN_BULK_UPLOAD_TEMPLATE,
// } from 'NetoworkHandler/api';

export const ADMIN = 'Admin';
export const B2B = 'B2B';
export const B2C = 'B2C';
export const SALES = 'Sales';
export const SUB_USER = 'SubUser';
export const TPIN = 'tpin';
export const TPIN2 = 'tpin2';
export const VES = 'ves';
export const TP = 'tp';
export const MGL = 'mgl';
export const YOU = 'you';
export const ASCPL = 'ascpl';
export const APSP = 'apsp';
export const TPB = 'tpb';
export const IFLI = 'ifli';
export const SELF_SCHEMA = 'S';
export const PELOCAL_SCHEMA = 'P';
export const OICL = 'oicl';
export const vor = 'vor_1';
export const vor2 = 'vrkg1';
export const OICLU = 'oiclu';
export const HATHWAY = 'hway';
export const SPEC = 'spce';
export const NICL = 'nicl';
export const EDEL = 'edel';
export const METL = 'metl';

export const ENCRYPTIONUSER = ['7461988', '7465088'];

// export const B2CMenu = [
// 	// { sidebarText: 'Bulk Upload', to: '/bulk-upload', icon: uploadIcon },
// 	{ sidebarText: 'DashBoard', to: '/dashboard', icon: dashBoardIcon },
// 	{
// 		sidebarText: 'Bulk Upload',
// 		to: '/bulkUpload-consumer',
// 		nestedTo: '/bulk-upload',
// 		nestedTo1: '/bulkUpload-adhoc',
// 		nestedTo2: '/payment-link',
// 		icon: uploadIcon,
// 	},
// 	{ sidebarText: 'Campaigns', to: '/campaign-list', icon: campaignIcon },
// 	// { sidebarText: 'Link Send', to: '/payment-link', icon: paymentIcon },
// 	{ sidebarText: 'Customers', to: '/customer', icon: customerIcon },
// 	{ sidebarText: 'Sub Users', to: '/sub-user', icon: sideBarSubUserIcon },
// 	{ sidebarText: 'Reminder', to: '/reminder', icon: reminderIcon },
// 	{ sidebarText: 'Report', to: '/payment-report', nestedTo: 'settlement-report', icon: reportIcon },
// 	{ sidebarText: 'Order History', to: '/merchant', icon: MerchantIcon },
// 	{ sidebarText: 'Settings', to: '/setting', icon: settingIcon },
// ];

// export const MGLMenu = [
// 	{ sidebarText: 'Transaction', to: '/payment-transaction', icon: paymentIcon },
// 	{ sidebarText: 'Reminder', to: '/reminder', icon: reportIcon },
// ];

// export const MGLASCPL = [{ sidebarText: 'Transaction', to: '/payment-transaction', icon: paymentIcon }];
// export const MGLAPSP = [
// 	{ sidebarText: 'Transaction', to: '/b2b-apsp-payment-transation', icon: paymentIcon },
// 	{ sidebarText: 'Reminder', to: '/reminder', icon: reportIcon },
// ];

// export const OICLMenu = [
// 	{ sidebarText: 'Data Upload', to: '/consumer-upload', icon: uploadIcon },
// 	{ sidebarText: 'Transaction', to: '/payment-transaction', icon: paymentIcon },
// 	{ sidebarText: 'Settlement', to: '/b2b-settlement-report', icon: MerchantIcon },
// 	{ sidebarText: 'Reminder', to: '/reminder', icon: reportIcon },
// ];
// export const VorMenu = [
// 	{ sidebarText: 'Transaction', to: '/payment-transaction', icon: paymentIcon },
// 	{ sidebarText: 'Settlement', to: '/b2b-settlement-report', icon: MerchantIcon },
// ];

// export const SPCEMenu = [
// 	{ sidebarText: 'Transaction', to: '/payment-transaction', icon: paymentIcon },
// 	{ sidebarText: 'Settlement', to: '/b2b-settlement-report', icon: MerchantIcon },
// ];

// export const B2BTPBRoutes = [
// 	{ sidebarText: 'Data Upload', to: '/consumer-upload', icon: uploadIcon },
// 	{ sidebarText: 'Send Invoice', to: '/send-invoice', icon: customerIcon },
// 	{ sidebarText: 'Transaction', to: '/payment-transaction', icon: paymentIcon },
// 	{ sidebarText: 'Reminder', to: '/reminder', icon: reportIcon },
// 	{ sidebarText: 'Settlement', to: '/b2b-settlement-report', icon: MerchantIcon },
// 	{ sidebarText: 'Invoice', to: '/Invoice', icon: paymentIcon },
// ];

// export const B2BMenu = [
// 	{ sidebarText: 'Data Upload', to: '/consumer-upload', icon: uploadIcon },
// 	{ sidebarText: 'Send Invoice', to: '/send-invoice', icon: customerIcon },
// 	{ sidebarText: 'Transaction', to: '/payment-transaction', icon: paymentIcon },
// 	{ sidebarText: 'Reminder', to: '/reminder', icon: reportIcon },
// 	{ sidebarText: 'Settlement', to: '/b2b-settlement-report', icon: MerchantIcon },
// 	{ sidebarText: 'Refund', to: '/refund-list', icon: customerIcon },
// 	{ sidebarText: 'Invoice', to: '/Invoice', icon: paymentIcon },
// ];

// export const defaultB2BMenu = [
// 	// { sidebarText: 'DashBoard', to: '/dashboard', icon: dashBoardIcon },
// 	{ sidebarText: 'Data Upload', to: '/consumer-upload', icon: uploadIcon },
// 	{ sidebarText: 'Send Invoice', to: '/send-invoice', icon: customerIcon },
// 	{ sidebarText: 'Transaction', to: '/payment-transaction', icon: paymentIcon },
// 	{ sidebarText: 'Reminder', to: '/reminder', icon: reportIcon },
// 	{ sidebarText: 'Settlement', to: '/b2b-settlement-report', icon: MerchantIcon },
// 	// { sidebarText: 'Refund', to: '/refund-list', icon: customerIcon },
// 	// { sidebarText: 'Invoice', to: '/Invoice', icon: paymentIcon },
// 	// { sidebarText: 'Dynamic Grid', to: '/sample', icon: paymentIcon },
// ];

// export const EDELMenu = [
// 	{ sidebarText: 'Data Upload', to: '/consumer-upload', icon: uploadIcon },
// 	{ sidebarText: 'Templates', to: '/template-upload', icon: uploadIcon },
// 	{ sidebarText: 'Send Invoice', to: '/send-invoice', icon: customerIcon },
// 	{ sidebarText: 'Transaction', to: '/payment-transaction', icon: paymentIcon },
// 	{ sidebarText: 'Reminder', to: '/reminder', icon: reportIcon },
// 	{ sidebarText: 'Settlement', to: '/b2b-settlement-report', icon: MerchantIcon },
// ];
// export const METLMenu = [
// 	{ sidebarText: 'Data Upload', to: '/consumer-upload', icon: uploadIcon },
// 	{ sidebarText: 'Templates', to: '/template-upload', icon: uploadIcon },
// 	{ sidebarText: 'Send Invoice', to: '/send-invoice', icon: customerIcon },
// 	{ sidebarText: 'Transaction', to: '/payment-transaction', icon: paymentIcon },
// 	{ sidebarText: 'Reminder', to: '/reminder', icon: reportIcon },
// 	{ sidebarText: 'Settlement', to: '/b2b-settlement-report', icon: MerchantIcon },
// ];

// export const HWAYMenu = [
// 	// { sidebarText: 'DashBoard', to: '/dashboard', icon: dashBoardIcon },
// 	{ sidebarText: 'Data Upload', to: '/consumer-upload', icon: uploadIcon },
// 	{ sidebarText: 'Templates', to: '/template-upload', icon: uploadIcon },
// 	// { sidebarText: 'Send Invoice', to: '/send-invoice', icon: customerIcon },
// 	{ sidebarText: 'Transaction', to: '/payment-transaction', icon: paymentIcon },
// 	{ sidebarText: 'Reminder', to: '/reminder', icon: reportIcon },
// 	{ sidebarText: 'Settlement', to: '/b2b-settlement-report', icon: MerchantIcon },
// 	// { sidebarText: 'Refund', to: '/refund-list', icon: customerIcon },
// 	// { sidebarText: 'Invoice', to: '/Invoice', icon: paymentIcon },
// 	// { sidebarText: 'Dynamic Grid', to: '/sample', icon: paymentIcon },
// ];

// export const NICLMenu = [
// 	{ sidebarText: 'Data Upload', to: '/consumer-upload', icon: uploadIcon },
// 	{ sidebarText: 'Transaction', to: '/payment-transaction', icon: paymentIcon },
// 	{ sidebarText: 'Reminder', to: '/reminder', icon: reportIcon },
// 	{ sidebarText: 'Settlement', to: '/b2b-settlement-report', icon: MerchantIcon },
// ];

// export const IFLIB2BMenu = [
// 	// { sidebarText: 'DashBoard', to: '/dashboard', icon: dashBoardIcon },
// 	{ sidebarText: 'Data Upload', to: '/consumer-upload', icon: uploadIcon },
// 	// { sidebarText: 'Send Invoice', to: '/send-invoice', icon: customerIcon },
// 	{ sidebarText: 'Transaction', to: '/payment-transaction', icon: paymentIcon },
// 	{ sidebarText: 'Reminder', to: '/reminder', icon: reportIcon },
// 	{ sidebarText: 'Settlement', to: '/b2b-settlement-report', icon: MerchantIcon },
// 	// { sidebarText: 'Refund', to: '/refund-list', icon: customerIcon },
// 	// { sidebarText: 'Invoice', to: '/Invoice', icon: paymentIcon },
// 	// { sidebarText: 'Dynamic Grid', to: '/sample', icon: paymentIcon },
// ];

// export const B2BMenuOtherMerchant = [
// 	{ sidebarText: 'Data Upload', to: '/consumer-upload', icon: uploadIcon },
// 	{ sidebarText: 'Transaction', to: '/payment-transaction', icon: paymentIcon },
// 	{ sidebarText: 'Reminder', to: '/reminder', icon: reportIcon },
// 	// { sidebarText: 'Settlement', to: '/b2b-settlement-report', icon: MerchantIcon },
// 	{ sidebarText: 'Refund', to: '/refund-list', icon: customerIcon },
// ];

// export const AdminMenu = [
// 	{ sidebarText: 'Health Check', to: '/health-check', icon: uploadIcon },
// 	{ sidebarText: 'Download', to: '/download-report', icon: reportIcon },
// 	{ sidebarText: 'Api Status', to: '/check-api-status', icon: MerchantIcon },
// 	// { sidebarText: 'DashBoard', to: '/admin-dashboard', icon: dashBoardIcon },
// ];

// export const SalesMenu = [
// 	{ sidebarText: 'Download', to: '/download-report', icon: reportIcon },
// 	{ sidebarText: 'Impersonate', to: '/impersonate-users', icon: uploadIcon },
// ];

// export const SubUserMenu = [{ sidebarText: 'Merchant', to: '/download-report', icon: sideBarSubUserIcon }];

// export const SelectedSubUserMenu = [
// 	{
// 		sidebarText: 'Bulk Upload',
// 		to: '/bulkUpload-consumer',
// 		nestedTo: '/bulk-upload',
// 		nestedTo1: '/bulkUpload-adhoc',
// 		nestedTo2: '/payment-link',
// 		icon: uploadIcon,
// 	},
// 	{ sidebarText: 'Customers', to: '/customer', icon: customerIcon },
// 	{ sidebarText: 'Report', to: '/payment-report', nestedTo: 'settlement-report', icon: reportIcon },
// ];

export const BlankMenu = [];

// export const UploadingUrl = [BULK_UPLOAD_URL, B2B_ADD_CAMPAIGN_URL, B2B_UPDATE_CAMPAIGN_URL];

export const RandomArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

export const TRIPLE_PLAY_CUSTOM_URL = 'tpin';

export const TEMPLATELOGOFILE = 'https://pelocal-production.s3.amazonaws.com/static/assets/images/ek-pahal-logo.png';

export const TEMPLATEBANNERFILE =
	'https://pelocal-production.s3.amazonaws.com/static/assets/images/donate_page_top_image.svg';

export const REMINDERLIST_ACTION_CONDITIONS = ['sent', 'fail', 'cancelled', 'sys cancelled'];
export const GET_ENVIRONMENT = process.env.NODE_ENV;

export const PRODUCTION = 'production';

export const DEVELOPMENT = 'development';

export const EXCEL_FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const SUPPORTED_LANG = ['en'];
export const PREVIOUS_MERCHANT_CODES = [TP, TPB, TPIN, MGL, APSP, ASCPL];

export const REFUND_NOT_ALLOWED_MERCHANTS = [TP, MGL, APSP, ASCPL];

export const UNDER_DEVELOPMENT_MESSAGE = 'This feature is currently in development';

export const REMINDER_EXPORT_MERCHANTS = [];

export const REMINDER_CANCEL_ALLOWED = [TP, TPB, TPIN, MGL, APSP, ASCPL];
export const CONSOLIDATED_SETTLEMENT_NOT_ALLOWED_MERCHANT = [TP, 'ucnd'];
export const API_ERROR = 'SomeThing went wrong';
export const GET_LANG_FILE_PATH = `${process.env.REACT_APP_PUBLIC_FILE_PATH}assets/locales/languageData/`;
export const REFRESH_TRANSACTION_NOT_ALLOWED_MERCHANTS = [MGL, APSP, ASCPL];
export const H8_ALLOWED_MERCHANT = [TPIN, TPIN2, TPB];
// export const FILE_DOWNLOAD_BASED_OF_MCODE = {
// 	ucnd: {
// 		FileHeaders: [
// 			'SubscriberId',
// 			'SubscriberRegistrationNo',
// 			'SubscriberName',
// 			'SubscriberRegisterMobileNo',
// 			'BillingAmount',
// 			'SetTopBoxPrepaidEntityCode',
// 			'ConnectionSetTopBoxNo',
// 			'ConnectionStatus',
// 			'OutstandingAmount',
// 			'LastReceiptDate',
// 			'LastReceiptAmt',
// 			'ExpiryDate',
// 		],
// 		FilePath: B2B_UCN_BULK_UPLOAD_TEMPLATE,
// 	},
// };
