import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
	name: 'counter',
	initialState: {
		value: 0,
		message: '',
		businessName: '',
		viewBusinessName: null,
		timeout: 4000,
		snackbarState: false,
		error: '',
		viewMenu: true,
		loaderState: false,
		phoneNumber: '',
		loaderCounter: 0,
		dataStatus: 'Not Updated',
	},
	reducers: {
		increment: (state) => {
			// Redux Toolkit allows us to write "mutating" logic in reducers. It
			// doesn't actually mutate the state because it uses the Immer library,
			// which detects changes to a "draft state" and produces a brand new
			// immutable state based off those changes
			state.value += 1;
		},
		decrement: (state) => {
			state.value -= 1;
		},
		incrementByAmount: (state, action) => {
			state.value += action.payload;
		},
		addMessageText: (state, action) => {
			state.message = action.payload;
		},
		addBusinessName: (state, action) => {
			state.businessName = action.payload;
		},
		setBusinessName: (state, action) => {
			state.viewBusinessName = action.payload;
		},
		clearBusinessName: (state) => {
			state.businessName = '';
			state.viewBusinessName = null;
		},
		addTimeOut: (state, action) => {
			state.timeout = action.payload;
		},
		hideMenu: (state, action) => {
			state.viewMenu = action.payload;
		},
		addError: (state, action) => {
			state.error = action.payload;
		},
		showSnackbar: (state, action) => {
			state.snackbarState = action.payload;
		},
		showLoader: (state, action) => {
			if (action.payload === true) {
				state.loaderCounter += 1;
			} else if (action.payload === false) {
				state.loaderCounter -= 1;
			}
			if (state.loaderCounter <= 1) {
				state.loaderState = action.payload;
			}
		},
		addPhoneNumber: (state, action) => {
			state.phoneNumber = action.payload;
		},
		clearPhoneNumber: (state) => {
			state.phoneNumber = '';
		},
		changeDataUpdateStatus: (state) => {
			state.dataStatus = 'Updated';
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	increment,
	decrement,
	incrementByAmount,
	addMessageText,
	addBusinessName,
	addTimeOut,
	addError,
	hideMenu,
	showSnackbar,
	showLoader,
	addPhoneNumber,
	clearPhoneNumber,
	changeDataUpdateStatus,
	setBusinessName,
	clearBusinessName,
} = appSlice.actions;

export default appSlice.reducer;
