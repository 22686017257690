/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import { Link } from "react-router-dom";

import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field } from "formik";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { getDecodedString } from "../../utils/Encoding";
import { addPolicyDetail, addUniqueID } from "../../store/reducers/userSlice";
import GetApiFunctionCall from '../../utils/GetApiCall';




const OtpValidate = () => {
  const [otp, setOtp] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [submiting, setSubmiting] = useState(false);
  const [checkBoxStatus, setCheckBoxStatus] = useState(false);
  const [showError, setShowError] = useState('');
  const [showOtpView, setShowOtpView ] = useState(false);
  const [message, setMessage] = useState('Click here to send otp at number');
  const [sendOtpMessage, setSendOtpMessage] = useState('');
  const dispatch = useDispatch();
  const {uniqueId} = useParams();
  let navigate = useNavigate();

  useEffect(()=>{
    // console.log('uniqueIduniqueId',uniqueId);
    dispatch(addUniqueID(uniqueId));


  },[]);

 useEffect(()=>{
    const url = `https://staging.pelocal.com/n/b2b/api/v1/ap/remote_promo_page/${uniqueId}/`;
    axios.get(url).then((response) => {
      // console.log('response----->>',response?.data);
			if (response.data !== undefined) {
        const dataSuccess = getDecodedString(response?.data);
        setMessage(dataSuccess.message);
        // if (response.data.success) {
        //   setMessage(response.data.message);
        // }
        // else {
        //   setLoaderState(false);
        //   showSnackbarFunctionCall(response.data.error, 4000, 'error');
        // }
      }
		});
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resendOTP = () => {
    setMinutes(1);
    setSeconds(30);
  };

  const handleSendOtp = ()=>{
    const url = `https://staging.pelocal.com/n/b2b/api/v1/ap/send_otp/${uniqueId}/`;
    axios.get(url).then((response) => {
      // console.log('send_otp response----->>',response);
			if (response.data !== undefined) {
        const dataSuccess = getDecodedString(response?.data);
        // console.log('dataSuccess---->>',dataSuccess);
        setShowOtpView(true);
        setSendOtpMessage(dataSuccess.message);
        // setMessage(dataSuccess.message);
        // if (response.data.success) {
          // setShowOtpView(true);
          // setSendOtpMessage(response.data.message);
        // } else {}
      }
		});
  }

  const handleSubmit = () => {
    const url = `https://staging.pelocal.com/n/b2b/api/v1/ap/verify_otp/${uniqueId}/`;


    axios.post(url, { otp: '123456' }).then((response) => {
      if(response.data.success){
        dispatch(addPolicyDetail(response.data.data.tma.tma_body.response.PremiumDetails));
        navigate(`/pnbmetlife/p/policy/${uniqueId}`);
      }
    });
  };


  const onCheckTCHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckBoxStatus(event.target.checked);
	};

  const verifyOtp = () => {
    setSubmiting(true);
    setTimeout(resetTimer, 10000);
  };

  const resetTimer = () => {
    setSubmiting(false);
  };
  return (
    <>
      <div className="opt-box row m-0 pt-3 pb-3">
        {!showOtpView ?
         <div className="d-flex justify-content-center mb-6 mt-3" style={{marginBottom:'20px'}}>
         <button className="btn next btn-primary btn-lg  mx-1" onClick={handleSendOtp}>{message}</button>
       </div>: ''
        }

          {showOtpView ?
           <div className="col-sm-6 mx-auto">

           <p className="text-center">
             <strong className="mb-1">Enter OTP</strong>
           </p>
           <p className="text-center">
             {sendOtpMessage}
           </p>
           {seconds > 0 || minutes > 0 ? (
             <p className="mb-1 text-center">
               <small>
                 Time Remaining:
                 <span id="timer">
                   {" "}
                   {minutes < 10 ? `0${minutes}` : minutes}:
                   {seconds < 10 ? `0${seconds}` : seconds}
                 </span>
               </small>
             </p>
           ) : (
             <p mb-0>
               {" "}
               <small>Didn't recieve code?</small>
             </p>
           )}

           <Formik
             initialValues={{ otp: "", termsAndCondition: "" }}
             validate={(values) => {
               const errors = {} as any;
               if (!values.otp) {
                 errors.otp = "Otp is required";
               } else if (!/^\d+$/.test(values.otp)) {
                 errors.otp = "please enter number only";
               }
               return errors;
             }}
             onSubmit={(values, { setSubmitting, resetForm }) => {
              const url = `https://staging.pelocal.com/n/b2b/api/v1/ap/verify_otp/${uniqueId}/`;

              if (values.otp === "123456"){
                axios.post(url, { otp: values.otp }).then((response) => {
                  // console.log('verify_otp response---->>',response);
                  if(response.data !== undefined){
                    const dataSuccess = getDecodedString(response?.data);
                    // console.log('decoded response---->>', JSON.stringify(dataSuccess));
                    // setMessage(dataSuccess.message);
                    // dispatch(addPolicyDetail(response.data.data.tma.tma_body.response.PremiumDetails));
                    dispatch(addPolicyDetail(dataSuccess?.data));
                    navigate(`/pnbmetlife/p/policy/${uniqueId}`);
                  }
                });
              }
              else if (values.otp === "654321") {
                setTimeout(() => {
                  // alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                  resetForm();
                  navigate("/pnbmetlife/wrongPloicy");
                }, 800);
              } else {
                setTimeout(() => {
                  alert("Please enter correct otp.");
                  setSubmitting(false);
                  resetForm();
                }, 400);
              }

              //  if (values.otp === "123456") {
              //    setTimeout(() => {
              //      // alert(JSON.stringify(values, null, 2));
              //      setSubmitting(false);
              //      resetForm();
              //      navigate("/policy");
              //    }, 800);
              //  } else if (values.otp === "654321") {
              //    setTimeout(() => {
              //      // alert(JSON.stringify(values, null, 2));
              //      setSubmitting(false);
              //      resetForm();
              //      navigate("/wrongPloicy");
              //    }, 800);
              //  } else {
              //    setTimeout(() => {
              //      alert("Please enter correct otp.");
              //      setSubmitting(false);
              //      resetForm();
              //    }, 400);
              //  }
             }}
            // onSubmit={(values) => handleFormSubmit(values)}

            // onSubmit={handleSubmit}

           >
             {({ isSubmitting, errors }) => (
               <Form>
                 <div className="opt-cont">
                   <Field
                     name="otp"
                     className="form-control border-none border-bottom text-center"
                     type="text"
                     placeholder="Enter OTP"
                     aria-label="default input example"
                     // value={otp
                     //   .replace(/[^0-9.]/g, "")
                     //   .replace(/(\..*)\./g, "$1")}
                     maxLength={6}
                     minLength={6}
                     // onInput={() => {
                     //   setOtp(
                     //     otp.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")
                     //   );
                     // }}
                     // onChange={({ target }: any) => {
                     //   setOtp(target.value);
                     // }}
                   />
                   {errors.otp ? (
                     <div>
                       <small className="text-danger">{errors.otp}</small>
                     </div>
                   ) : null}
                 </div>
                 <p className="text-right">
                   <small>
                     <button
                       className="btn next  btn-lg"
                       disabled={seconds > 0 || minutes > 0}
                       style={{
                         color:
                           seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630",
                       }}
                       onClick={(e) => resendOTP()}
                     >
                       Resend OTP
                     </button>
                   </small>
                 </p>
                 <div className='terms_and_conditions' style={{ marginBottom: '50px' }}>
                 <input
                   type='checkbox'
                   name='termsAndCondition'
                   checked={checkBoxStatus}
                   onChange={(event) => onCheckTCHandler(event)}
                 />
                 <span>
                   {'By proceeding you agree to our'}
                   <a href='https://pelocal.com/terms-and-conditions' target='_blank' rel='noreferrer'>
                     {`Terms and Conditions`}
                   </a>
                   &
                   <a href='https://pelocal.com/privacy' target='_blank' rel='noreferrer'>
                     {`Privacy`}
                   </a>
                 </span>
                 <p>..</p>
                 <p></p>
                 <p></p>

                 {/* {errors.termsAndCondition ? (
                     <div>
                       <small className="text-danger">{errors.termsAndCondition}</small>
                     </div>
                   ) : null} */}

               </div>
               {/* {errors.termsAndCondition ? (
                     <div>
                       <small className="text-danger">XYZ</small>
                     </div>
                   ) :
                   <div>
                   <small className="text-danger">Please check terms and conditions</small>
                 </div>} */}
               {/* {!checkBoxStatus ?
               <p style={{ color: 'red', marginTop:'0px' }}>
               <small>{showError}</small>
             </p>: ''} */}
                 <div className="d-flex justify-content-center">
                   <button
                     type="submit"
                     className="btn next btn-primary btn-lg  mx-1"
                     // onClick={verifyOtp}
                     disabled={isSubmitting}
                   >
                     {isSubmitting ? (
                       <span
                         className="spinner-border spinner-border-sm"
                         role="status"
                         aria-hidden="true"
                       ></span>
                     ) : (
                       "Submit"
                     )}
                   </button>
                 </div>

               </Form>
             )}
           </Formik>
         </div>: ''
          }

      </div>
    </>
  );
};

export default OtpValidate;
