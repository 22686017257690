import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
	name: 'counter',
	initialState: {
		user: null,
		userDetail: {},
		policyDetail: {},
		merchantDetail: [],
		userServices: [],
		selectedPortal: null,
		impersonatorState: false,
		subUserLoggedInState: false,
		impersonatorUserToken: null,
		impersonatorUserDetail: null,
		clearUserDetail: null,
		clearMerchantDetails: null,
		reinitializeState: null,
		merchantCode: '',
		uniqueID: null,
	},
	reducers: {
		addUser: (state, action) => {
			state.user = action.payload;
		},
		addUserDetail: (state, action) => {
			state.userDetail = action.payload;
		},
		addPolicyDetail: (state, action) => {
			state.policyDetail = action.payload;
		},
		addUniqueID: (state, action) => {
			state.uniqueID = action.payload;
		},
		addMerchantDetail: (state, action) => {
			state.merchantDetail = action.payload;
		},
		addUserServices: (state, action) => {
			state.userServices = action.payload;
		},
		addSelectedPortal: (state, action) => {
			state.selectedPortal = action.payload;
		},
		addImpersonatorState: (state, action) => {
			state.impersonatorState = action.payload;
		},
		addImpersonatorUserToken: (state, action) => {
			state.impersonatorUserToken = action.payload;
		},
		addImpersonatorUserDetail: (state, action) => {
			state.impersonatorUserDetail = action.payload;
		},
		addSubUserLoggedInState: (state, action) => {
			state.subUserLoggedInState = action.payload;
		},
		clearUserDetails: (state) => {
			state.userDetail = {};
		},
		clearMerchantDetails: (state) => {
			state.merchantDetail = [];
		},
		reinitializeState: (state) => {
			state.user = state.impersonatorUserToken;
			state.userDetail = state.impersonatorUserDetail;
		},
		clearUserStore: (state) => {
			state.user = null;
			state.userDetail = {};
			state.merchantDetail = [];
			state.userServices = [];
			state.selectedPortal = null;
			state.impersonatorState = false;
			state.impersonatorUserToken = null;
			state.impersonatorUserDetail = null;
			state.subUserLoggedInState = false;
		},
		clearImpersonateStates: (state) => {
			state.impersonatorState = false;
			state.impersonatorUserToken = null;
			state.impersonatorUserDetail = null;
			state.merchantDetail = [];
		},
		clearSubUSerStates: (state) => {
			state.subUserLoggedInState = false;
			state.impersonatorUserToken = null;
			state.impersonatorUserDetail = null;
		},
		updateMerchantCode: (state, action) => {
			state.merchantCode = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	addUser,
	updateMerchantCode,
	addUserDetail,
	addPolicyDetail,
	addUniqueID,
	addMerchantDetail,
	addUserServices,
	addSelectedPortal,
	clearUserDetails,
	clearMerchantDetails,
	clearUserStore,
	addImpersonatorState,
	addImpersonatorUserToken,
	addImpersonatorUserDetail,
	addSubUserLoggedInState,
	clearImpersonateStates,
	clearSubUSerStates,
	reinitializeState,
} = userSlice.actions;

export default userSlice.reducer;
