
import { useNavigate } from "react-router-dom";

const WrongPloicy = () => {
    let navigate = useNavigate();
    const gotoOtp=()=>{
        navigate("/")
    }
  return (
    <>
      <div className="opt-box row m-0 pt-3 pb-3">
        <div className="col-sm-6 mx-auto">
          <h5 className="text-center mb-2">
            <strong className="mb-1">The registered phone number doesnot match with the policy number. </strong>
          </h5>
        </div>
      </div>
      <div className="d-flex justify-content-center mb-6 mt-3">
         <button className="btn next btn-primary btn-lg  mx-1" onClick={gotoOtp}>back</button>
      </div>
    </>
  );
};

export default WrongPloicy;
