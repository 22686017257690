/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import getincomeicon from "../../assets/images/get-income-icon.png";
import certificate from "../../assets/images/certificate.png";
import cashicon from "../../assets/images/cash-icon.png";

import bigmoneyicon from "../../assets/images/bigmoney-icon.png";
import securepayment from "../../assets/images/secure-payment.png";
import timer from "../../assets/images/timer-icon.png";
import documentdownload from "../../assets/images/document-download.png";
import watchvideo from "../../assets/images/watch-video.png";
import { useNavigate } from "react-router-dom";

const Home = () => {
  let navigate = useNavigate();

  const onNextClick =()=>{
    navigate("/poli")
  }
  return (
    <>
      <div className="row g-0">
        <div className="col-md-12">
          <h1 className="title-heading">PNB MetLife Century Plan</h1>
        </div>
      </div>
      <div className="row mt-6 m-0 ml-3 justify-content-between list-items">
                <div className="d-flex align-items-center inline-stat">
                  <div className="mb-lg-0">
                    <div className="icon ">
                      <img src={getincomeicon} />
                    </div>
                  </div>
                  <div className="card-text">
                    <p>
                      Get income and protection for whole life.
                    </p>
                  </div>
                </div>          
                <div className="d-flex align-items-center inline-stat">
                  <div className=" mb-lg-0">
                    <div className="icon ">
                      <img src={certificate} />
                    </div>
                  </div>
                  <div className="card-text">
                    <p>Leave a legacy for your loved once.</p>
                  </div>
                </div>
                <div className="d-flex align-items-center inline-stat">
                  <div className=" mb-lg-0">
                    <div className="icon ">
                      <img src={cashicon} />
                    </div>
                  </div>
                  <div className="card-text">
                    <p>Get Guaranteed Cash bonus</p>
                    <p>@10% under Suuper Income option</p>
                    <p>@30% under Future Income option</p>
                  </div>
                </div>
                <div className="d-flex align-items-center inline-stat">
                  <div className=" mb-lg-0">
                    <div className="icon ">
                      <img src={bigmoneyicon} />
                    </div>
                  </div>
                  <div className="card-text">
                    <p>
                      Ensure survival benefits continue to be paid even after
                      death of the Life Assured and no future premiums are
                      payable after death.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center inline-stat">
                  <div className=" mb-lg-0">
                    <div className="icon ">
                      <img src={securepayment} />
                    </div>
                  </div>
                  <div className="card-text">
                    <p>
                      Safeguard your Financial needs even after retirement.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center inline-stat">
                  <div className=" mb-lg-0">
                    <div className="icon ">
                      <img src={timer} />
                    </div>
                  </div>
                  <div className="card-text">
                    <p>
                      Enjoy the flexibility to accumulate the survival benefits
                      payouts and withdraw as and when required.
                    </p>
                  </div>
                </div>
          </div>

        <div className="row g-0">
          <div className="col-md-12">
            <h1 className="title-heading">To Know More</h1>
          </div>
        </div>
        <div className="row mt-6 m-0">
          <div className="col-12 d-flex justify-content-around">
            <div className="mb-6 text-center d-flex">
              <a href="#" className="gradient-btn  d-flex justify-content-between">
                <img src={documentdownload} />
                <span className="text-right">Policy<br/> Document<br/> Download</span>
              </a>
              <a href="#" className="gradient-btn text-right d-flex m-0">
                <img src={watchvideo} />
                <span className="text-right">Product<br/> Video</span>
              </a>
            </div>
          </div>
          </div>
        <footer>
          <div className="d-flex justify-content-center mb-6">
            <button className="btn review btn-outline-primary btn-lg mx-1">Click To Revive</button>
            <button className="btn next btn-primary btn-lg  mx-1" onClick={onNextClick}>Next</button>
          </div>
        </footer>
    </>
  );
};

export default Home;
