/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import pnbMetlifeLogo from "../../assets/images/pnbMetlifeLogo.png";

const Header =() =>{
  return (
    <>
     <header className="pb-3 py-4 mb-5" style={{ position: "relative" }}>
          <a
            href="/"
            className="d-flex align-items-center text-dark text-decoration-none zindex"  >
            <img src={pnbMetlifeLogo} className="logo img-fluid" />
          </a>
        </header>
    </>
  )
}

export default Header